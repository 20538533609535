import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Welcome from "./Welcome";
import Recommend from "./Recommend";
import CyberRecommend from "./CyberRecommend";
import UserStats from "./UserStats";
import { GlobalStateProvider } from "./GlobalStateContext"; // Import the context provider
import Paywall from "./Paywall";

function App() {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [moduleCount, setModuleCount] = useState(0);

  const screens = [
    <Welcome />,
    <Recommend setModuleCount={setModuleCount} />,
    <CyberRecommend setModuleCount={setModuleCount} />,
    <UserStats />
  ];

  const breadcrumbs = ["Welcome", "Recommend", "CyberRecommend", "User Stats"];

  const handleBreadcrumbClick = (index) => {
    setCurrentScreen(index);
  };

  return (
    <div className='App'>
      <CssBaseline />
      <Container
        maxWidth='xxl'
        sx={{ height: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ mb: 2, overflowY: "auto", flexGrow: 1 }}>
          {screens[currentScreen]}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <Breadcrumbs aria-label='breadcrumb'>
            {breadcrumbs.map((label, index) => (
              <Link
                key={label}
                underline='hover'
                color={index === currentScreen ? "text.primary" : "inherit"}
                onClick={() => handleBreadcrumbClick(index)}
                style={{ cursor: "pointer" }}
                aria-current={index === currentScreen ? "page" : undefined}
              >
                {label}
              </Link>
            ))}
          </Breadcrumbs>
        </Box>
      </Container>
    </div>
  );
}

export default App;
