import React from "react";
import logo from "./dcc-nobg.png";
import "./App.css";

function Welcome() {
  return (
    <div className='welcome-container'>
      <header className='welcome-header'>
        <img src={logo} className='welcome-logo' alt='logo' />
        <h1 className='welcome-title'>Welcome to LabCreator</h1>
        <p className='welcome-subtitle'>
          Your AI-powered platform for designing and generating customized labs
        </p>
      </header>

      <main className='welcome-content'>
        <div className='feature-card'>
          <h3 className='feature-title'>Intelligent Lab Generation</h3>
          <p className='feature-description'>
            Our AI-powered engine analyzes your requirements and generates
            tailored lab environments, ensuring optimal learning experiences.
          </p>
        </div>

        <div className='feature-card'>
          <h3 className='feature-title'>Customizable Labs</h3>
          <p className='feature-description'>
            Personalize your labs with specific scenarios, skill requirements,
            and objectives to meet your unique training needs.
          </p>
        </div>

        <div className='feature-card'>
          <h3 className='feature-title'>Cyber Focused Labs</h3>
          <p className='feature-description'>
            Create your labs with cyber specific scenarios, utilizing are in
            depth database of cyber skills, job roles and labs.
          </p>
        </div>

        <div className='feature-card'>
          <h3 className='feature-title'>Interactive Learning</h3>
          <p className='feature-description'>
            Engage with hands-on exercises, real-world simulations, and
            interactive challenges to reinforce your skills.
          </p>
        </div>
      </main>

      <footer className='footer'>
        <p>
          &copy; {new Date().getFullYear()} DotComCrowd.com. All rights
          reserved.
        </p>
      </footer>
    </div>
  );
}

export default Welcome;
