import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Avatar,
  LinearProgress,
  Chip
} from "@mui/material";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, remove, onValue } from "firebase/database";
import { styled } from "@mui/material/styles";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBySt_t4B9muRAcBS3gbNg5uD9_coWNBUs",
  authDomain: "maxcyber-bb1db.firebaseapp.com",
  databaseURL: "https://maxcyber-bb1db-default-rtdb.firebaseio.com",
  projectId: "maxcyber-bb1db",
  storageBucket: "maxcyber-bb1db.appspot.com",
  messagingSenderId: "731591064258",
  appId: "1:731591064258:web:ca75fca9a76b880f46995b"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const UserStats = () => {
  const [labs, setLabs] = useState([]);

  useEffect(() => {
    const fetchLabs = () => {
      const labsRef = ref(database, "labs");
      onValue(labsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setLabs(Object.values(data));
        }
      });
    };
    fetchLabs();
  }, []);

  // Calculate user stats
  const labsCompleted = labs.length;
  const totalModules = labs.reduce(
    (total, lab) => total + lab.selectedModules.length,
    0
  );
  const totalCompletedModules = labs.reduce(
    (total, lab) => total + lab.selectedModules.length,
    0
  );
  const score = labsCompleted * 10;

  const handleDeleteLab = async (labId) => {
    await remove(ref(database, `labs/${labId}`));
    setLabs((prevLabs) => prevLabs.filter((lab) => lab.labId !== labId));
  };

  return (
    <Box className='container'>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={12} md={8}>
          <Card variant='outlined' className='card'>
            <CardContent>
              <Grid container alignItems='center' spacing={2}>
                <Grid item>
                  <Avatar
                    style={{
                      backgroundColor: "#0078ff",
                      marginRight: "0.5rem",
                      height: "70px",
                      width: "70px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                      transform:
                        "perspective(500px) rotateX(10deg) rotateY(10deg)"
                    }}
                  >
                    Max
                  </Avatar>
                </Grid>
                <Grid item>
                  <h2 className='welcome-title-stats'>User Stats </h2>
                </Grid>
              </Grid>
              <Typography variant='body1' gutterBottom className='card-text'>
                <strong>Labs Completed:</strong> {labsCompleted}
              </Typography>
              <Typography variant='body1' gutterBottom className='card-text'>
                <strong>Level:</strong> {score}/1000
              </Typography>
              <Box sx={{ width: "100%", mt: 2 }}>
                <LinearProgress
                  variant='determinate'
                  value={(score / 1000) * 100}
                  color='primary'
                />
              </Box>
            </CardContent>
          </Card>
          <Card variant='outlined' className='card' sx={{ mt: 3 }}>
            <CardContent>
              <h3 className='welcome-title-stats-saved'>Saved labs </h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ fontWeight: "bold", color: "#0078ff" }}
                      >
                        Lab Name
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", color: "#0078ff" }}
                      >
                        Completed Modules
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", color: "#0078ff" }}
                      >
                        Completion Percentage
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", color: "#0078ff" }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {labs.map((lab, index) => (
                      <TableRow key={index}>
                        <TableCell>{lab.labName}</TableCell>
                        <TableCell>{lab.selectedModules.join(", ")}</TableCell>
                        <TableCell>{lab.completionPercentage}%</TableCell>
                        <TableCell>
                          <Button
                            variant='contained'
                            color='error'
                            onClick={() => handleDeleteLab(lab.labId)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div className='footer'>
        <p>
          &copy; {new Date().getFullYear()} DotComCrowd.com. All rights
          reserved.
        </p>
      </div>
    </Box>
  );
};

export default UserStats;
