import React, { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OpenAI from "openai";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import logo from "./dcc-nobg.png";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox"; // Add this import
import FormGroup from "@mui/material/FormGroup"; // Add this import
import FormControlLabel from "@mui/material/FormControlLabel"; // Add thi
import { initializeApp } from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import { getDatabase, ref, push, remove, onValue } from "firebase/database";
import TextField from "@mui/material/TextField"; // Import TextField
import { jsPDF } from "jspdf";

const openai = new OpenAI({
  apiKey: "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", // Replace with your actual API key
  dangerouslyAllowBrowser: true
});

const firebaseConfig = {
  apiKey: "AIzaSyBySt_t4B9muRAcBS3gbNg5uD9_coWNBUs",
  authDomain: "maxcyber-bb1db.firebaseapp.com",
  databaseURL: "https://maxcyber-bb1db-default-rtdb.firebaseio.com",
  projectId: "maxcyber-bb1db",
  storageBucket: "maxcyber-bb1db.appspot.com",
  messagingSenderId: "731591064258",
  appId: "1:731591064258:web:ca75fca9a76b880f46995b"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const data = {
  "Incident Response and Cyber Investigations": {
    "Live Windows examination": ["SIFT", "HELIX Live"],
    "Network investigation": [
      "Tcpdump",
      "WireShark",
      "Network Miner",
      "Splunk",
      "Snort"
    ],
    "Memory investigation": ["FTK", "BlackLight", "Volitility", "SANS SIFT"],
    "Malware investigation": [
      "PeStudio",
      "Process Hacker",
      "Process Monitor (ProcMon)",
      "ProcDot",
      "Autoruns",
      "Fiddler",
      "Wireshark",
      "x64dbg",
      "Ghidra",
      "Radare2",
      "Cutter",
      "Cuckoo Sandbox"
    ],
    "Cloud investigation": [
      "SpiderFoot",
      "Encase Enterprise",
      "FTK",
      "FORST",
      "UFED cloud analyzer",
      "Container Exploration Toolkit",
      "Docker Forensics Toolkit",
      "Diffy"
    ]
  },
  "Recon, Scanning, and Enumeration Attacks": {
    "Open-Source Intelligence": [
      "Domo",
      "Wyn Enterprise",
      "Toucan",
      "Google Cloud BigQuery",
      "SAS Visual Analytics",
      "Phocas Software",
      "Planful",
      "Zoho Analytics",
      "NetSuite",
      "Convirza"
    ],
    "Domain Name System (DNS) Reconnaissance and Enumeration": [
      "Dig",
      "Host",
      "DNSenum",
      "Nmap",
      "DNS Recon",
      "Fierce",
      "SecurityTrails advanced DNS enumeration"
    ],
    "Host Discovery and Assessment": [
      "Nmap",
      "SolarWinds",
      "ManageEngine",
      "Intermapper",
      "Atera",
      "NetBrain",
      "WhatsUp Gold",
      "Datadog",
      "Nagios",
      "Intermapper",
      "Advanced IP Scanner"
    ],
    "Shadow Cloud Asset Discovery": ["Masscan", "CASB"],
    "Windows Server Message Block (SMB) Session Attacks": ["Metasploit"],
    "Windows Password Spray Attack Detection": ["Splunk"]
  },
  "Password and Access Attacks": {
    "Local password Guessing Attacks": [
      "Hydra",
      "Hashcat",
      "John the Ripper",
      "Brutus",
      "Wfuzz"
    ],
    "Password Cracking": ["John the Ripper", "Hashcat"],
    "Cloud Bucket Discovery": ["S3crets Scanner"],
    "Uses of Netcat": ["Netcat"]
  },
  "Public-Facing and Drive-By Attacks": {
    "Metasploit Attack and Analysis": ["Metasploit"],
    "Client-side Exploitation with the Browser Exploitation Framework (BeEF)": [
      "BeEF"
    ],
    "Windows System Resource Usage Database Analysis": [
      "Windows System Resource Utilization Monitor"
    ],
    "Command Injection Attack": ["Commix"],
    "Cross-Site Scripting Attack": ["XSS Scanner", "Burpsuite"],
    "SQL Injection Attack": ["Burpsuite"],
    "Server Side Request Forgery (SSRF) and Instance Metadata Service (IMDS) Attack":
      ["Burp Suite – Enforce IMDSv2"]
  },
  "Evasion and Post-Exploitation Attacks": {
    "Endpoint Protection Bypass: Offensive IAT Hooking / Altering API Calls": [
      "IDA Pro"
    ],
    "Pivoting and Lateral Movement": ["Metasploit"],
    "MITM attack": ["Ettercap"],
    "Establishing Persistence": ["Metasploit", "Netcat"],
    "Network Threat Hunting with Real Intelligence Threat Analytics (RITA)": [
      "RITA"
    ],
    "Cloud Configuration Assessment": ["ScoutSuite"]
  },
  "Capture-the-Flag Event": {
    "Build a Red team Blue Team Lab (GNU)": [
      "Nagios",
      "Metasploit",
      "Linux",
      "Open VPN (Local)"
    ],
    "CodeSeige.net Lab": ["DCC CS SAAS"]
  }
};

function Recommend() {
  const [inputOne, setInputOne] = useState("");
  const [inputTwo, setInputTwo] = useState("");
  const [inputThree, setInputThree] = useState("");
  const [output, setOutput] = useState("");
  const [loading, setLoading] = useState(false);
  const [moduleCount, setModuleCount] = useState(0);
  const [selectedModules, setSelectedModules] = useState([]);
  const [bugReport, setBugReport] = useState(""); // New state for bug reporting
  const completionPercentage = Math.round(
    (selectedModules.length / moduleCount) * 100
  );

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleModuleSelection = (module) => (event) => {
    const index = selectedModules.indexOf(module);
    if (index === -1) {
      setSelectedModules([...selectedModules, module]);
    } else {
      setSelectedModules([
        ...selectedModules.slice(0, index),
        ...selectedModules.slice(index + 1)
      ]);
    }
  };

  const generateUniqueId = () => {
    // Generate a UUID (Universally Unique Identifier)
    return uuidv4();
  };

  const saveToDatabase = () => {
    // Save lab name and selected modules to the Firebase Realtime Database
    // const labId = generateUniqueId(); // Implement this function to generate a unique ID
    const labName = output.split("\n\n")[0].substring(12); // Extract lab name
    const labRef = ref(database, "labs");
    const newLabRef = push(labRef, {
      labName: labName,
      selectedModules: selectedModules,
      completionPercentage: completionPercentage,
      bugReport: bugReport // Save the bug report to the database
    });

    // Listen for completion of the operation
    onValue(newLabRef, (snapshot) => {
      if (snapshot.exists()) {
        // Data saved successfully
        alert("Lab saved successfully!");
      } else {
        // Error occurred while saving data
        alert("Error occurred while saving lab. Please try again.");
      }
    });
  };

  const generateRecommendation = async () => {
    setLoading(true);
    try {
      const prompt = `Taking into account the selection, Skill Category: ${inputOne}\nDesired Job Skill they want to learn: ${inputTwo}\nLab Selection: ${inputThree}, use those inputs to create a lab that will help them learn their desired skill. Utilize the following structure:

1. **Lab name**
2. **Lab introduction**
3. **Lab resources**
4. **Lab walkthrough (i.e., commands to run and their purpose)**

The lab must be broken down into clear, concise modules (e.g., Module 1, Module 2, etc.).

This lab must be exceptionally detailed being no less than 600 words. Each step must be described with utmost clarity, ensuring that no detail is overlooked. Provide step-by-step instructions for every single task. This lab needs to be comprehensive and detailed so that anyone, regardless of their skill level, can follow along. Make no assumptions about prior knowledge; every action, command, and decision must be explained thoroughly.

**Key points to emphasize:**
- Every instruction must be clear and precise. Avoid vague directions.
- Each command or action must be accompanied by a detailed explanation of its purpose and expected outcome.
- The lab must be structured in a way that each module builds upon the previous one, ensuring a logical flow from start to finish.
- Use clear formatting to distinguish commands that need to be run from the instructional text. For example, use bold or code formatting for commands.
- Include troubleshooting tips for common issues that may arise at each step.

**Module structure example:**
- **Module 1: [Module Title]**
  - Step-by-step instructions
  - Detailed explanations
  - Commands to run, clearly formatted
  - Expected outcomes

- **Module 2: [Module Title]**
  - Step-by-step instructions
  - Detailed explanations
  - Commands to run, clearly formatted
  - Expected outcomes

Ensure the lab can be completed from start to finish with the given tasks and resources. The instructions must be so detailed that they eliminate any ambiguity, allowing the participant to complete the lab successfully without needing additional guidance. The clarity and thoroughness of these instructions are of utmost importance.
IMPORTANT: TAKE YOUR TIME, I PREFER A COMPREHENSIVE ANSWER TO A SHORT ONE, YOUR RESPONSE SHOULD BE ATLEAST 2000 WORDS, DO NOT FACTOR TIME TO REPSOND TO ME OR LENGTH OF THE RESPONSE, ASSUME BOTH ARE INFINITE AND GET THE BEST POSSIBLE ANSWER.`;

      const completion = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        max_tokens: 3671,
        n: 1,
        messages: [
          {
            role: "system",
            content:
              "Examine the users inputs to assess the participant's desired skill they want to learn and their method of doing so. Based on this evaluation, create a suitable lab tailored to their needs."
          },
          { role: "user", content: prompt }
        ]
      });
      console.log(completion); // Log the entire response
      setOutput(completion.choices[0].message.content);
      const moduleCount = (
        completion.choices[0].message.content.match(/Module \d+/g) || []
      ).length;
      console.log("Number of modules:", moduleCount);
      setModuleCount(moduleCount);
    } catch (error) {
      console.error("Error generating recommendation:", error);
      setOutput(`Failed to generate recommendation. Error: ${error.message}`);
    }
    setLoading(false);
  };

  const getSkillsForCategory = (category) => {
    return Object.keys(data[category] || {});
  };

  const getToolsForSkill = (category, skill) => {
    return data[category]?.[skill] || [];
  };

  const saveAsPdf = () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    const lineHeight = 10;
    const margin = 10;
    let yOffset = margin;
    const textLines = doc.splitTextToSize(
      output,
      doc.internal.pageSize.width - 2 * margin
    );

    textLines.forEach((line) => {
      if (yOffset + lineHeight > pageHeight - margin) {
        doc.addPage();
        yOffset = margin;
      }
      doc.text(line, margin, yOffset);
      yOffset += lineHeight;
    });

    doc.save("lab.pdf");
  };

  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <h1 className='welcome-title'>Cyber Lab Creator </h1>
        <p className='welcome-subtitle'>
          Create your lab with choices from our cutting-edge cyber database!
        </p>
        <Box sx={{ width: "55%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <FormControl fullWidth margin='normal'>
                <InputLabel id='input-one-label'>Skill Category</InputLabel>
                <Select
                  labelId='input-one-label'
                  id='input-one'
                  value={inputOne}
                  label='Skill Category'
                  onChange={handleInputChange(setInputOne)}
                  sx={{
                    width: 500,
                    "& .MuiOutlinedInput-root": {},
                    "& .MuiOutlinedInput-input": {
                      backgroundColor: "#96ecff72",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      padding: "17px"
                    }
                  }}
                >
                  {Object.keys(data).map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin='normal'>
                <InputLabel id='input-two-label'>
                  Demonstrated Job Skills
                </InputLabel>
                <Select
                  labelId='input-two-label'
                  id='input-two'
                  value={inputTwo}
                  label='Demonstrated Job Skills'
                  onChange={handleInputChange(setInputTwo)}
                  disabled={!inputOne}
                  sx={{
                    "& .MuiOutlinedInput-root": {},
                    "& .MuiOutlinedInput-input": {
                      backgroundColor: "#96ecff72",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      padding: "17px"
                    }
                  }}
                >
                  {getSkillsForCategory(inputOne).map((skill) => (
                    <MenuItem key={skill} value={skill}>
                      {skill}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin='normal'>
                <InputLabel id='input-three-label'>Tool (Labs)</InputLabel>
                <Select
                  labelId='input-three-label'
                  id='input-three'
                  value={inputThree}
                  label='Tool (Labs)'
                  onChange={handleInputChange(setInputThree)}
                  disabled={!inputTwo}
                  sx={{
                    "& .MuiOutlinedInput-root": {},
                    "& .MuiOutlinedInput-input": {
                      backgroundColor: "#96ecff72",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      padding: "17px"
                    }
                  }}
                >
                  {getToolsForSkill(inputOne, inputTwo).map((tool) => (
                    <MenuItem key={tool} value={tool}>
                      {tool}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Button
          variant='contained'
          onClick={generateRecommendation}
          disabled={loading}
          sx={{
            margin: "30px",
            borderRadius: "50px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Get Recommendation"}
        </Button>

        {output && (
          <div className={`Preview-Box ${output ? "show" : ""}`}>
            {output && <h1 className='Preview-Box-Title'>Recommendation:</h1>}
            <div className='Preview-Box-Text'>
              {output.split("\n\n").map((section, index) => (
                <div
                  key={index}
                  style={{ marginBottom: "20px", textAlign: "left" }}
                >
                  {section.split("\n").map((line, index) => {
                    if (
                      line.startsWith("**Lab Name:**") ||
                      line.startsWith("##")
                    ) {
                      return (
                        <p
                          key={index}
                          style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                            fontSize: line.startsWith("**Lab Name:**")
                              ? "20px"
                              : "18px",
                            textAlign: "left"
                          }}
                        >
                          {line.substring(
                            line.startsWith("**Lab Name:**") ? 12 : 3
                          )}
                        </p>
                      );
                    } else if (line.startsWith("-**") && line.endsWith("**")) {
                      return (
                        <p
                          key={index}
                          style={{
                            fontWeight: "bold",
                            marginBottom: "5px",
                            textAlign: "left"
                          }}
                        >
                          {line.substring(3, line.length - 2)}
                        </p>
                      );
                    } else if (line.startsWith("**") && line.endsWith("**")) {
                      return (
                        <p
                          key={index}
                          style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                            fontSize: "18px",
                            textAlign: "left"
                          }}
                        >
                          {line.substring(2, line.length - 2)}
                        </p>
                      );
                    } else if (line.startsWith("- ") && line.includes(":")) {
                      const [label, content] = line.substring(2).split(":");
                      return (
                        <div
                          key={index}
                          style={{ marginBottom: "10px", textAlign: "left" }}
                        >
                          <p
                            style={{
                              fontWeight: "bold",
                              marginBottom: "5px",
                              textAlign: "left"
                            }}
                          >
                            {label}
                          </p>
                          <p style={{ fontSize: "16px", textAlign: "left" }}>
                            {content}
                          </p>
                        </div>
                      );
                    } else if (
                      line.startsWith("- ") &&
                      line.match(/^(-\s*Step\s*\d+:)/i)
                    ) {
                      return (
                        <p
                          key={index}
                          style={{
                            marginBottom: "5px",
                            textAlign: "left",
                            fontWeight: "bold"
                          }}
                        >
                          {line}
                        </p>
                      );
                    } else {
                      return (
                        <p
                          key={index}
                          style={{ fontSize: "16px", textAlign: "left" }}
                        >
                          {line}
                        </p>
                      );
                    }
                  })}
                </div>
              ))}
            </div>
          </div>
        )}
        {moduleCount > 0 && (
          <div style={{ marginTop: "40px" }}>
            <Typography
              variant='h4'
              component='h1'
              sx={{
                fontWeight: 700,
                textAlign: "center",
                marginBottom: 2
              }}
            >
              Module Checklist
            </Typography>
            <FormGroup
              sx={{
                marginTop: 2,
                padding: 2,
                borderRadius: "8px",
                border: "1px solid",
                borderColor: "divider",
                backgroundColor: "background.paper"
              }}
            >
              {Array.from({ length: moduleCount }, (_, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedModules.includes(`Module ${index + 1}`)}
                      onChange={handleModuleSelection(`Module ${index + 1}`)}
                      sx={{ color: "primary.main" }}
                    />
                  }
                  label={`Module ${index + 1}`}
                  sx={{ margin: "8px 0" }}
                />
              ))}
            </FormGroup>
            <Typography
              variant='body1'
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "20px"
              }}
            >
              Completion: {completionPercentage}%
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant='h6'>Bug Tracker</Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder='Describe any bugs or issues you encountered...'
                value={bugReport}
                onChange={(e) => setBugReport(e.target.value)}
                variant='outlined'
              />
            </Box>
            <Button
              variant='contained'
              onClick={saveToDatabase}
              disabled={!output || selectedModules.length === 0}
              sx={{
                margin: "30px",
                borderRadius: "50px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
              }}
            >
              Save
            </Button>
            <Button
              onClick={saveAsPdf}
              variant='contained'
              sx={{
                margin: "30px",
                borderRadius: "50px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
              }}
            >
              Save Lab as PDF
            </Button>
          </div>
        )}
      </header>
    </div>
  );
}

export default Recommend;
