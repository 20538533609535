import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import OpenAI from "openai";
import Box from "@mui/material/Box";
import logo from "./dcc-nobg.png";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { jsPDF } from "jspdf";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox"; // Add this import
import FormGroup from "@mui/material/FormGroup"; // Add this import
import FormControlLabel from "@mui/material/FormControlLabel"; // Add thi
import { initializeApp } from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import { getDatabase, ref, push, remove, onValue } from "firebase/database";

const openai = new OpenAI({
  apiKey: "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", // Replace with your actual API key
  dangerouslyAllowBrowser: true
});

const firebaseConfig = {
  apiKey: "AIzaSyBySt_t4B9muRAcBS3gbNg5uD9_coWNBUs",
  authDomain: "maxcyber-bb1db.firebaseapp.com",
  databaseURL: "https://maxcyber-bb1db-default-rtdb.firebaseio.com",
  projectId: "maxcyber-bb1db",
  storageBucket: "maxcyber-bb1db.appspot.com",
  messagingSenderId: "731591064258",
  appId: "1:731591064258:web:ca75fca9a76b880f46995b"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

function Recommend() {
  const [inputOne, setInputOne] = useState("");
  const [inputTwo, setInputTwo] = useState("");

  const [inputThree, setInputThree] = useState("");
  const [inputFour, setInputFour] = useState("");
  // ... add as many inputs as you need
  const [output, setOutput] = useState("");
  const [loading, setLoading] = useState(false);
  const [moduleCount, setModuleCount] = useState(0);
  const [bugReport, setBugReport] = useState(""); // New state for bug reporting
  const [selectedModules, setSelectedModules] = useState([]);
  const completionPercentage = Math.round(
    (selectedModules.length / moduleCount) * 100
  );

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleModuleSelection = (module) => (event) => {
    const index = selectedModules.indexOf(module);
    if (index === -1) {
      setSelectedModules([...selectedModules, module]);
    } else {
      setSelectedModules([
        ...selectedModules.slice(0, index),
        ...selectedModules.slice(index + 1)
      ]);
    }
  };

  const generateUniqueId = () => {
    // Generate a UUID (Universally Unique Identifier)
    return uuidv4();
  };

  const saveToDatabase = () => {
    // Save lab name and selected modules to the Firebase Realtime Database
    // const labId = generateUniqueId(); // Implement this function to generate a unique ID

    const labNameRegex =
      /(?:Lab(?:oratory)?\s*(?:Name|Title)?\s*:)(.*)(?:\n|$)/i;
    const labNameMatch = output.match(labNameRegex);

    let labName = "";
    if (labNameMatch && labNameMatch[1]) {
      labName = labNameMatch[1].trim();
    }

    const labRef = ref(database, "labs");
    const newLabRef = push(labRef, {
      labName: labName,
      selectedModules: selectedModules,
      completionPercentage: completionPercentage,
      bugReport: bugReport // Save the bug report to the database
    });

    // Listen for completion of the operation
    onValue(newLabRef, (snapshot) => {
      if (snapshot.exists()) {
        // Data saved successfully
        alert("Lab saved successfully!");
      } else {
        // Error occurred while saving data
        alert("Error occurred while saving lab. Please try again.");
      }
    });
  };

  const generateRecommendation = async () => {
    setLoading(true);
    try {
      const prompt = `Taking into account the users, Current Role: ${inputOne}\nDesired Role: ${inputTwo}\nDesired Skill: ${inputThree}, Desired Skill: ${inputFour} use those inputs to create a lab that will help them learn their desired skill, utilise the following structure:
      
  
      1. **Lab name**
      2. **Lab introduction**
      3. **Lab resources**
      4. **Lab walkthrough (i.e., commands to run and their purpose)**
      
      The lab must be broken down into clear, concise modules (e.g., Module 1, Module 2, etc.).
      
      This lab must be exceptionally detailed being no less than 600 words. Each step must be described with utmost clarity, ensuring that no detail is overlooked. Provide step-by-step instructions for every single task. This lab needs to be comprehensive and detailed so that anyone, regardless of their skill level, can follow along. Make no assumptions about prior knowledge; every action, command, and decision must be explained thoroughly.
      
      **Key points to emphasize:**
      - Every instruction must be clear and precise. Avoid vague directions.
      - Each command or action must be accompanied by a detailed explanation of its purpose and expected outcome.
      - The lab must be structured in a way that each module builds upon the previous one, ensuring a logical flow from start to finish.
      - Use clear formatting to distinguish commands that need to be run from the instructional text. For example, use bold or code formatting for commands.
      - Include troubleshooting tips for common issues that may arise at each step.

      Must include Lab Name: (the name of the lab) to the right of it, this format is important
      
      **Module structure example:**
      - **Module 1: [Module Title]**
        - Step-by-step instructions
        - Detailed explanations
        - Commands to run, clearly formatted
        - Expected outcomes
      
      - **Module 2: [Module Title]**
        - Step-by-step instructions
        - Detailed explanations
        - Commands to run, clearly formatted
        - Expected outcomes
  
        Ensure the lab can be completed start to finish with the given task and resources, make it clear that the text such as commands they need to run are easily distinguishable from instruction content.
        IMPORTANT: TAKE YOUR TIME, I PREFER A COMPREHENSIVE ANSWER TO A SHORT ONE, YOUR RESPONSE SHOULD BE ATLEAST 2000 WORDS, DO NOT FACTOR TIME TO REPSOND TO ME OR LENGTH OF THE RESPONSE, ASSUME BOTH ARE INFINITE AND GET THE BEST POSSIBLE ANSWER.`;
      const completion = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        max_tokens: 3671,
        n: 1,
        messages: [
          {
            role: "system",
            content:
              "Examine the users inputs to assess the participant's skill level and interests. Based on this evaluation, identify a suitable role, a specific skill within that role, and an accompanying lab exercise. The selection process should ensure that the lab aligns with the participant's skill proficiency and areas of interest, facilitating an optimal learning experience tailored to their needs."
          },
          { role: "user", content: prompt }
        ]
      });
      console.log(completion); // Log the entire response
      setOutput(completion.choices[0].message.content);
      const moduleCount = (
        completion.choices[0].message.content.match(/Module \d+/g) || []
      ).length;
      console.log("Number of modules:", moduleCount);
      setModuleCount(moduleCount);
    } catch (error) {
      console.error("Error generating recommendation:", error);
      setOutput(`Failed to generate recommendation. Error: ${error.message}`);
    }
    setLoading(false);
  };

  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <h1 className='welcome-title'>Custom Lab Creator</h1>
        <p className='welcome-subtitle'>
          Create your custom lab with your desired values!
        </p>
        <Box sx={{ width: "55%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <TextField
                id='input-one'
                label='Current Role'
                variant='outlined'
                value={inputOne}
                onChange={handleInputChange(setInputOne)}
                margin='normal'
                sx={{
                  width: 500,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                    border: "1px solid #00CFFF"
                  },
                  "& .MuiOutlinedInput-input": {
                    backgroundColor: "#96ecff72",
                    borderRadius: "30px",
                    padding: "17px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='input-two'
                label='Desired Role'
                variant='outlined'
                value={inputTwo}
                onChange={handleInputChange(setInputTwo)}
                margin='normal'
                sx={{
                  width: 500,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                    border: "1px solid #00CFFF"
                  },
                  "& .MuiOutlinedInput-input": {
                    backgroundColor: "#96ecff72",
                    borderRadius: "30px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    padding: "17px"
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='input-three'
                label='Desired Skill'
                variant='outlined'
                value={inputThree}
                onChange={handleInputChange(setInputThree)}
                margin='normal'
                sx={{
                  width: 500,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                    border: "1px solid #00CFFF"
                  },
                  "& .MuiOutlinedInput-input": {
                    backgroundColor: "#96ecff72",
                    borderRadius: "30px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    padding: "17px"
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='input-four'
                label='Technology / Framework focus'
                variant='outlined'
                value={inputFour}
                onChange={handleInputChange(setInputFour)}
                margin='normal'
                sx={{
                  width: 500,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                    border: "1px solid #00CFFF"
                  },
                  "& .MuiOutlinedInput-input": {
                    backgroundColor: "#96ecff72",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "30px",
                    padding: "17px"
                  }
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Button
          variant='contained'
          onClick={generateRecommendation}
          disabled={loading}
          sx={{
            margin: "30px",
            borderRadius: "50px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Get Recommendation"}
        </Button>
        {output && (
          <div className={`Preview-Box ${output ? "show" : ""}`}>
            {output && <h1 className='Preview-Box-Title'>Recommendation:</h1>}
            <div className='Preview-Box-Text'>
              {output.split("\n\n").map((section, index) => (
                <div
                  key={index}
                  style={{ marginBottom: "20px", textAlign: "left" }}
                >
                  {section.split("\n").map((line, index) => {
                    if (
                      line.startsWith("**Lab Name:**") ||
                      line.startsWith("##")
                    ) {
                      return (
                        <p
                          key={index}
                          style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                            fontSize: line.startsWith("**Lab Name:**")
                              ? "20px"
                              : "18px",
                            textAlign: "left"
                          }}
                        >
                          {line.substring(
                            line.startsWith("**Lab Name:**") ? 12 : 3
                          )}
                        </p>
                      );
                    } else if (line.startsWith("-**") && line.endsWith("**")) {
                      return (
                        <p
                          key={index}
                          style={{
                            fontWeight: "bold",
                            marginBottom: "5px",
                            textAlign: "left"
                          }}
                        >
                          {line.substring(3, line.length - 2)}
                        </p>
                      );
                    } else if (line.startsWith("**") && line.endsWith("**")) {
                      return (
                        <p
                          key={index}
                          style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                            fontSize: "18px",
                            textAlign: "left"
                          }}
                        >
                          {line.substring(2, line.length - 2)}
                        </p>
                      );
                    } else if (line.startsWith("- ") && line.includes(":")) {
                      const [label, content] = line.substring(2).split(":");
                      return (
                        <div
                          key={index}
                          style={{ marginBottom: "10px", textAlign: "left" }}
                        >
                          <p
                            style={{
                              fontWeight: "bold",
                              marginBottom: "5px",
                              textAlign: "left"
                            }}
                          >
                            {label}
                          </p>
                          <p style={{ fontSize: "16px", textAlign: "left" }}>
                            {content}
                          </p>
                        </div>
                      );
                    } else if (
                      line.startsWith("- ") &&
                      line.match(/^(-\s*Step\s*\d+:)/i)
                    ) {
                      return (
                        <p
                          key={index}
                          style={{
                            marginBottom: "5px",
                            textAlign: "left",
                            fontWeight: "bold"
                          }}
                        >
                          {line}
                        </p>
                      );
                    } else {
                      return (
                        <p
                          key={index}
                          style={{ fontSize: "16px", textAlign: "left" }}
                        >
                          {line}
                        </p>
                      );
                    }
                  })}
                </div>
              ))}
            </div>
          </div>
        )}
        {moduleCount > 0 && (
          <div style={{ marginTop: "40px" }}>
            <Typography
              variant='h4'
              component='h1'
              sx={{
                fontWeight: 700,
                textAlign: "center",
                marginBottom: 2
              }}
            >
              Module Checklist
            </Typography>
            <FormGroup
              sx={{
                marginTop: 2,
                padding: 2,
                borderRadius: "8px",
                border: "1px solid",
                borderColor: "divider",
                backgroundColor: "background.paper"
              }}
            >
              {Array.from({ length: moduleCount }, (_, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedModules.includes(`Module ${index + 1}`)}
                      onChange={handleModuleSelection(`Module ${index + 1}`)}
                      sx={{ color: "primary.main" }}
                    />
                  }
                  label={`Module ${index + 1}`}
                  sx={{ margin: "8px 0" }}
                />
              ))}
            </FormGroup>
            <Typography
              variant='body1'
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "20px"
              }}
            >
              Completion: {completionPercentage}%
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant='h6'>Bug Tracker</Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder='Describe any bugs or issues you encountered...'
                value={bugReport}
                onChange={(e) => setBugReport(e.target.value)}
                variant='outlined'
              />
            </Box>
            <Button
              variant='contained'
              onClick={saveToDatabase}
              disabled={!output || selectedModules.length === 0}
              sx={{
                margin: "30px",
                borderRadius: "50px"
              }}
            >
              Save
            </Button>
          </div>
        )}
      </header>
    </div>
  );
}

export default Recommend;
